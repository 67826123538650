<template>
  <div>
<!--    <div style="margin-top: 30vh">
      <van-cell-group>
        <van-field v-model="phone" label="手机号" placeholder="请输入手机号" />
      </van-cell-group>
      <div  style="width:calc(100% - 100px); margin: 40px auto">
        <van-button round type="primary" size="large" @click.stop="oauth()">确认绑定</van-button>
      </div>

    </div>-->
    <div style="margin-top: 20vh">
      <van-form @submit="oauth">
        <van-cell-group inset>
          <van-field
              v-model="phone"
              type="phone"
              name="手机号"
              label="手机号"
              placeholder="手机号"
              :rules="[{ required: true, message: '请填写手机号' }]"
          />
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <div class="block" >
          <van-loading color="#1989fa" />
        </div>
      </div>
    </van-overlay>

  </div>
</template>

<script>
import {Icon, Field, Button, CellGroup, Cell, CheckboxGroup, NoticeBar,Notify , Toast, Dialog,Form,Overlay,Loading  } from 'vant';
import httpApi from "@/api/httpApi";
import apiUri from "@/api/uri";
import tool from "@/utils/tool";

let validatorPhone = (val) => !/^1(3|4|5|6|7|8|9)\d{9}$/.test(val);
export default {
  name: "login",
  components: {
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Dialog.name]: Dialog ,
    [Notify.name]: Notify  ,
    [Form.name]: Form,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [Button.name]: Button
  },
  data() {
    return {
      show:false,
      phone: ''
    }
  },
  created(res) {
    if (this.$route.query.code && this.$route.query.state) {
      this.show = true
      const query ={
        code: this.$route.query.code,
        phone: this.$route.query.state
      }
      httpApi.post(apiUri.wx_auth,query).then(r => {
        if (r.data.code === 0 ){
          tool.handleSessionStorage('set', 'token', r.data.data)
          this.$router.replace({path: '/go'})
          this.show = false
        } else {
          Toast.fail(r.data.message)
        }
      }).catch(e => {
        // console.log(e)
        Toast.fail(e.data.message)
      })


    }
  },
  mounted(res) {
    console.log(res)
  },
  methods: {
    oauth() {
      if (this.phone === '' || this.phone.length !== 11){
        Notify({ type: 'warning', message: '请输入正确手机号' });
        return false
      }


      Dialog.confirm({
        title: '标题',
        message: '请确认绑定手机'+ this.phone,

        confirmButtonColor:"#073cc1"
      })
          .then(() => {
            let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxdc75d70f69bdab1b&redirect_uri=https://h5.tms.maimiaoscm.com/login&response_type=code&scope=snsapi_userinfo&state="+this.phone+"#wechat_redirect"
            console.log(url)
            window.location.href=url
          })
          .catch(() => {

          });

    }
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
}
.block >div{
  margin: 0 auto;
  text-align: center;
}
</style>
