// import { formatTime } from '@/utils/date-tool.js'

export default {
  convertUnit(value, unit = 'g2d') {
    let result
    switch (unit) {
      case 'g2d':
        result = value / 1000000
        break
      case 'd2g':
        break
      default:
        result = value
        break
    }
    return result
  },
  // 存储本地数据
  handleSessionStorage(method, key, value) {
    switch (method) {
      case 'get' : {
        const temp = window.sessionStorage.getItem(key)
        if (temp) {
          return temp
        } else {
          return ''
        }
      }
      case 'set' : {
        window.sessionStorage.setItem(key, value)
        break
      }
      case 'remove': {
        window.sessionStorage.removeItem(key)
        break
      }
      default : {
        return ''
      }
    }
  },
  /**
   * This is just a simple version of deep copy
   * Has a lot of edge cases bug
   * If you want to use a perfect deep copy, use lodash's _.cloneDeep
   */
  deepClone(source) {
    if (!source && typeof source !== 'object') {
      throw new Error('error arguments', 'deepClone')
    }
    const targetObj = source.constructor === Array ? [] : {}
    Object.keys(source).forEach(keys => {
      if (source[keys] && typeof source[keys] === 'object') {
        targetObj[keys] = this.deepClone(source[keys])
      } else {
        targetObj[keys] = source[keys]
      }
    })
    return targetObj
  },
  /**
   * 转化枚举
   * @param enums
   * @param code
   * @returns {string|string|rules.dicName|{trigger, message, required}|*}
   */
  convertEm(enums, code) {
    if (enums == null || enums.length <= 0) {
      return ''
    }
    for (let i = 0; i < enums.length; i++) {
      const em = enums[i]
      if (em.hasOwnProperty('code') && (em.code === code || em.code === String(code))) {
        return em.label
      } else if (em.hasOwnProperty('value') && (em.value === code || em.value === String(code))) {
        return em.name
      } else if (em.hasOwnProperty('key') && em.key === code) {
        return em.name
      }
    }
    return ''
  },
  convertStyle(enums, code) {
    if (enums == null || enums.length <= 0) {
      return null
    }
    code = code + ''
    for (let i = 0; i < enums.length; i++) {
      const em = enums[i]
      if (em.hasOwnProperty('code') && em.code === code) {
        return em
      }
    }
    return null
  },
  addDefault(enums) {
    if (enums == null || enums.length <= 0) {
      return enums
    }

    const hasDefault = enums.some((item) => {
      return item.code === ''
    })

    if (hasDefault) {
      return enums
    }

    const defaultItem = {
      code: '', label: '全部'
    }
    // 用于在数组的第一个位置添加元素，并返回添加新元素后的数组长度。注意，该方法会改变原数组。
    enums.unshift(defaultItem)
    return enums
  },
  // 二进制 权限判断
  binaryRole(binarys, int) {
    const roles = []
    binarys.forEach(item => {
      // 二进制位运算
      if ((item.code & int) === item.code) {
        roles.push(item.label)
      }
    })
    return roles
  },
  replaceAll(body, s1, s2) {
    return body.replace(new RegExp(s1, 'gm'), s2)
  },
  // 导出
  /**
   * @param filterVal
   * @param jsonData
   */
  // exportCurrentPage(tableData, tableColums, filename, dateFlag) {
  //   if (tableData == null) {
  //     this.$message.error('暂无数据导出')
  //   } else {
  //     import('@/vendor/Export2Excel').then(excel => {
  //       const filterVal = []
  //       const tHeader = []
  //       tableColums.forEach(element => {
  //         if (element.label !== '操作') {
  //           filterVal.push(element.prop)
  //           tHeader.push(element.label)
  //         }
  //       })
  //       if (dateFlag) {
  //         filename = filename + formatTime(new Date())
  //       }
  //       const data = this.formatJson(filterVal, tableData)
  //       excel.export_json_to_excel({
  //         header: tHeader,
  //         data,
  //         filename: filename,
  //         autoWidth: true,
  //         bookType: 'xlsx'
  //       })
  //     })
  //   }
  // },
  formatJson(filterVal, jsonData) {
    return jsonData.map(v => filterVal.map(j => {
      return v[j]
    })
    )
  }
}
